import { Component, inject, Input, OnInit } from '@angular/core';
import {
    Alert,
    AlertType,
    AlerteurService,
} from 'src/app/service/alerteur.service';

@Component({
    selector: 'app-toaster',
    templateUrl: './toaster.component.html',
    styleUrls: ['./toaster.component.scss'],
})
export class ToasterComponent implements OnInit {
    @Input() alert: Alert; // default type=text
    alertType = AlertType;

    faIconName = {
        info: 'fa-circle-info',
        success: 'fa-circle-check',
        warning: 'fa-circle-check',
        error: 'fa-circle-exclamation',
        error_permanent: 'fa-circle-exclamation',
        progress: '',
    };

    public alertService: AlerteurService = inject(AlerteurService);

    constructor() {}

    ngOnInit(): void {}

    close(alert: Alert) {
        this.alertService.inactive(alert);
    }
}
