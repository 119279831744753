import { Component, inject, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlerteurService } from 'src/app/service/alerteur.service';
import { WbceServiceManagerService } from 'src/app/service/wbce-service-manager.service';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
    subs: Subscription[] = [];
    deployed: boolean = true;

    public alertService: AlerteurService = inject(AlerteurService);
    public wbceServiceManager: WbceServiceManagerService = inject(
        WbceServiceManagerService
    );

    constructor() {}

    ngOnInit(): void {
        this.subs.push(
            this.alertService.$alerts.subscribe(this.onAlertChange.bind(this))
        );
    }

    ngOnDestroy() {
        for (let s of this.subs) {
            s.unsubscribe();
        }
    }

    onAlertChange() {}

    deployNotification() {
        this.deployed = !this.deployed;
    }
}
