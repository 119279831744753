<ng-container *ngIf="deployed">
    <ng-container *ngFor="let alert of alertService.alertList">
        <ng-container *ngIf="alert.active && deployed">
            <div class="notification">
                <app-toaster id="digestion-info" [alert]="alert">
                    {{ alert.message }}
                    <ng-container *ngIf="alert.complement.length > 0">
                        <br />{{ alert.complement }}
                    </ng-container>
                </app-toaster>
            </div>
        </ng-container>
    </ng-container>
</ng-container>

<div class="menu" *ngIf="alertService.getListSize()">
    <app-wbce-button class="full-width" (click)="deployNotification()">
        <mat-icon
            inline
            fontSet="fa"
            [fontIcon]="!deployed ? 'fa-angle-down' : 'fa-angle-up'"
        >
        </mat-icon>
        <div *ngIf="!deployed" class="compteur">
            {{ alertService.getListSize() }}
        </div>
    </app-wbce-button>
</div>
