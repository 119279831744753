<div id="first-part">
    <div class="content wbce-container centered gap32" style="margin-top: 20px">
        <fieldset class="wbce">
            <legend>Project</legend>

            <mat-select
                (selectionChange)="onProjectServiceSelected()"
                [(value)]="projectName"
            >
                <ng-container *ngFor="let project of projects">
                    <mat-option
                        [disabled]="project.isDisabled()"
                        [value]="project.projectName"
                    >
                        {{ project?.projectName }}
                    </mat-option>
                </ng-container>
            </mat-select>
        </fieldset>

        <fieldset class="wbce">
            <legend>Service</legend>
            <mat-select
                (selectionChange)="onProjectServiceSelected()"
                [(value)]="serviceName"
            >
                <ng-container *ngFor="let serviceItemMenu of servicesMenu">
                    <mat-option
                        [disabled]="serviceItemMenu.isDisabled"
                        [value]="serviceItemMenu.name"
                    >
                        {{ serviceItemMenu?.name }}
                    </mat-option>
                </ng-container>
            </mat-select>
        </fieldset>
    </div>

    <div id="line"></div>

    <div class="content wbce-container centered">
        <ng-container *ngFor="let item of menu">
            <app-wbce-button
                class="menu-list"
                [selected]="item.route === currentActiveRoute"
                [isDisabled]="item.workinprogress"
                [role]="item.readRole ? readRole : undefinedRole"
                [type]="'menu-list'"
                routerLink="{{ item.route }}"
            >
                <div class="borderd-color">
                    <mat-icon
                        mat-list-icon
                        class="shape"
                        [svgIcon]="item.shape"
                    >
                    </mat-icon>
                    <span style="text-wrap: nowrap">{{ item.name }}</span>
                    <mat-icon
                        mat-list-icon
                        class="shape"
                        *ngIf="item.workinprogress"
                        fontSet="fa"
                        fontIcon="fa-person-digging"
                    ></mat-icon>
                </div>
            </app-wbce-button>
        </ng-container>
    </div>

    <div id="line"></div>

    <div class="content wbce-container centered">
        <ng-container
            *ngFor="let service of servicesToDisplay; let index = index"
        >
            <app-wbce-button
                class="menu-list"
                [type]="'menu-list'"
                [matMenuTriggerFor]="menuService"
            >
                <div>
                    <mat-icon
                        mat-list-icon
                        class="shape"
                        [svgIcon]="technosName[service.techno.id]"
                    >
                    </mat-icon>
                    <span>{{ service?.caculatedDisplayName }}</span>
                </div>
            </app-wbce-button>
            <mat-menu #menuService="matMenu">
                <ng-container *ngFor="let ctxt of contextes">
                    <button
                        mat-menu-item
                        [matTooltip]="
                            service.isDisabled(ctxt)
                                ? 'No versions have been deployed. Go to the version tab and deploy a version.'
                                : ''
                        "
                        (click)="openContentInNewTab(service, ctxt)"
                        [matTooltipPosition]="'after'"
                        [disabled]="service?.isDisabled(ctxt)"
                    >
                        <span>Access to {{ ctxt.toLowerCase() }}</span>
                    </button>
                </ng-container>
            </mat-menu>
        </ng-container>
    </div>

    <ng-container *ngIf="serviceName === ALL_SERVICES">
        <div id="line"></div>

        <div class="content wbce-container centered">
            <ng-container *ngFor="let tool of tools; let index = index">
                <app-wbce-button
                    class="menu-list"
                    [type]="'menu-list'"
                    [matMenuTriggerFor]="menuTools"
                >
                    <div>
                        <mat-icon
                            mat-list-icon
                            class="shape"
                            [svgIcon]="tool.logo"
                        >
                        </mat-icon>
                        <span style="text-wrap: nowrap">{{ tool.name }}</span>
                    </div>
                </app-wbce-button>
                <mat-menu #menuTools="matMenu">
                    <button mat-menu-item (click)="openToolInNewTab(tool)">
                        <span>Access to website</span>
                    </button>
                </mat-menu>
            </ng-container>
        </div>
    </ng-container>
</div>
