<div class="session-expired-dialog">
    <p *ngIf="data === informationType.SESSION_EXPIRED">
        Your session has expired. Please refresh the page to continue.
    </p>
    <p *ngIf="data === informationType.KEYCLOAK_NOT_INIT">
        Unable to connect to keycloak. <br />Contact us or refresh the page
        later to continue.
    </p>
    <p *ngIf="data === informationType.API_DOWN">
        Unable to connect to Webcapsule. <br />
        Contact us or refresh the page later to continue.
    </p>
    <app-wbce-button type="outlined" (click)="reloadPage()"
        >Reload</app-wbce-button
    >
</div>
