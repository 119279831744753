<div [ngClass]="alert.type" id="toaster">
    <div class="display">
        <div class="content">
            <mat-icon
                inline
                fontSet="fa"
                class="fa-solid"
                [ngClass]="faIconName[alert.type]"
                [fontIcon]="faIconName[alert.type]"
                *ngIf="faIconName[alert.type] != ''"
            >
            </mat-icon>
            <span
                [ngClass]="
                    alertService.isPermanent(alert)
                        ? 'content-text-close'
                        : 'content-text'
                "
            >
                <ng-content></ng-content>
            </span>

            <ng-container *ngIf="alertService.isPermanent(alert)">
                <mat-icon
                    inline
                    fontSet="fa"
                    class="fa-close action"
                    (click)="close(alert)"
                >
                </mat-icon>
            </ng-container>
        </div>

        <div class="bottom">
            <ng-container *ngIf="alert.type == alertType.PROGRESS">
                <mat-progress-bar
                    class="notification-progress"
                    mode="indeterminate"
                    color="accent"
                >
                </mat-progress-bar>
            </ng-container>
        </div>
    </div>
</div>
