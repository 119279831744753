import { Component, inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
    app_logo,
    app_name,
    app_title,
    NavigationDescription,
    navigationDescription,
    PathType,
} from '../../enums/navigation';

export interface AppDescription {
    name: string;
    title: string;
    picture: string;
}

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    @Input() path: string;
    @Input() fixed: boolean = false;

    subs: Subscription[] = [];

    app: AppDescription = {
        name: app_name,
        title: app_title,
        picture: './../../assets/pictures/technos/' + app_logo + '.png',
    };

    currentPathDescription: PathType;

    public router: Router = inject(Router);
    public route: ActivatedRoute = inject(ActivatedRoute);

    constructor() {}

    ngOnInit(): void {
        this.subs.push(
            this.router.events
                .pipe(filter((e) => e instanceof NavigationEnd))
                .subscribe(this.onRouteChange.bind(this)) // ajouter un pipe pour filtrer les events non necessaires ?
        );

        this.changeContexte();
    }

    ngOnDestroy() {
        for (let s of this.subs) {
            s.unsubscribe();
        }
    }

    onRouteChange() {
        this.changeContexte();
    }

    changeContexte() {
        let routePath = this.route.firstChild?.snapshot?.url[0]?.path;
        let path: string = routePath ? routePath : this.path;

        if (navigationDescription[path]) {
            this.currentPathDescription = navigationDescription[path];
            document.documentElement.style.setProperty(
                '--theme-color',
                this.currentPathDescription.color
            ); // voir empacement et si cela  est la bonne commande
        }
    }
}
