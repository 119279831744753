import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { contextes } from '../enums/contextes';

export interface FilterType {
    serviceId?: string;
    contexte?: contextes | string;
}

export enum AlertCode {
    FIRST_DEPLOY = 1,
    API_ERROR = 2,
    KEYCLOAK_ERROR = 3,
    ACTION = 4,
    DEPLOY = 5,
    ADD = 6,
    MERGE = 7,
}

export enum AlertType {
    WARNING = 'warning',
    INFO = 'info',
    SUCCESS = 'success',
    PROGRESS = 'progress',
    ERROR = 'error',
    ERROR_PERMANENT = 'error_permanent',
}

export class Alert {
    timestamp: Date;
    validity: number; //duree
    code: AlertCode;
    type: string; // add enum
    active: boolean;
    filter: FilterType;
    complement: string;
    message: string;

    constructor(
        message: string,
        code?: AlertCode,
        type: AlertType = AlertType.INFO,
        filter: FilterType = {},
        complement: string = '',
        public author = 'front'
    ) {
        this.message = message;
        this.timestamp = new Date();
        this.code = code;
        this.type = type;
        this.active = true;
        this.filter = filter;
        this.complement = complement;
    }
}

@Injectable({
    providedIn: 'root',
})
export class AlerteurService {
    alertList: Alert[] = [];
    time = 8000; // increase time
    $alerts = new BehaviorSubject<Alert[]>(this.alertList);
    constructor() {}

    addAlert(alert: Alert) {
        let id = -1;
        if (alert.filter && Object.keys(alert.filter).length !== 0) {
            id = this.alertList.findIndex(
                (a) =>
                    a.filter?.serviceId === alert.filter?.serviceId &&
                    a.filter?.contexte === alert.filter?.contexte &&
                    a.code === alert.code
            );
        }

        if (id < 0) id = this.alertList.push(alert) - 1;

        this.$alerts.next(this.alertList);
        this.removeByTimer(this.alertList[id]);
        return this.alertList[id];
    }

    addOrUpdateAlert(alert: Alert) {
        let id = -1;
        if (alert.filter) {
            id = this.alertList.findIndex(
                (a) =>
                    a.filter?.serviceId === alert.filter?.serviceId &&
                    a.filter?.contexte === alert.filter?.contexte &&
                    a.code === alert.code
            );
        }

        if (id < 0) {
            this.alertList.push(alert);
            id = this.alertList.length - 1;
        } else {
            if (alert.type != this.alertList[id].type)
                this.alertList[id].active = alert.active; // restore display active if type change
            this.alertList[id].type = alert.type;
            this.alertList[id].complement = alert.complement;
            this.alertList[id].message = alert.message;
        }

        this.$alerts.next(this.alertList);
        this.removeByTimer(this.alertList[id]);
        return this.alertList[id];
    }

    updateIfExistAlert(alert: Alert) {
        let id = -1;
        if (alert.filter) {
            id = this.alertList.findIndex(
                (a) =>
                    a.filter?.serviceId === alert.filter?.serviceId &&
                    a.filter?.contexte === alert.filter?.contexte &&
                    a.code === alert.code
            );
        }

        if (id >= 0) {
            if (alert.type != this.alertList[id].type)
                this.alertList[id].active = alert.active; // restore display active if type change
            this.alertList[id].type = alert.type;
            this.alertList[id].complement = alert.complement;
            this.alertList[id].message = alert.message;

            this.$alerts.next(this.alertList);
            this.removeByTimer(this.alertList[id]);
        }
    }

    removeAlert(alert: Alert) {
        const alertIndex = this.alertList.findIndex((a) => a === alert);
        if (alertIndex >= 0) {
            this.alertList.splice(alertIndex, 1);
            this.$alerts.next(this.alertList);
        }
    }

    removeAllAlertWithCode(code: AlertCode) {
        this.alertList = this.alertList.filter((a) => a.code !== code);
        this.$alerts.next(this.alertList);
    }

    switchAlert(alert: Alert, type: AlertType) {
        const alertIndex = this.alertList.findIndex((a) => a === alert);
        if (alertIndex >= 0) {
            this.alertList[alertIndex].type = type;
            this.$alerts.next(this.alertList);
            this.removeByTimer(this.alertList[alertIndex]);
        }
    }

    isPermanent(alert: Alert) {
        return (
            alert.type === AlertType.PROGRESS ||
            alert.type === AlertType.ERROR_PERMANENT ||
            alert.type === AlertType.INFO
        );
    }

    inactive(alert: Alert) {
        const alertIndex = this.alertList.findIndex((a) => a === alert);
        if (alertIndex >= 0) {
            this.alertList[alertIndex].active = false;
        }
    }

    inactiveFilter(code: AlertCode, filter: FilterType) {
        const alertIndex = this.alertList.findIndex(
            (a) =>
                a.filter?.serviceId === filter.serviceId &&
                a.filter?.contexte === filter.contexte &&
                a.code === code
        );
        if (alertIndex >= 0) {
            this.alertList[alertIndex].active = false;
        }
    }

    removeByTimer(alert: Alert) {
        if (!this.isPermanent(alert)) {
            setTimeout(() => {
                alert.active = false;
                this.$alerts.next(this.alertList);
            }, this.time);
        }
    }

    getListSize() {
        return this.alertList.filter((alert) => alert.active).length;
    }
}
