<div [ngClass]="state">
    <div id="separator" *ngIf="selfStepIndex > 0"></div>

    <div id="step" [ngClass]="state">
        <ng-container [ngSwitch]="state">
            <div id="icon" *ngSwitchDefault>
                {{ selfStepIndex }}
            </div>

            <mat-icon
                *ngSwitchCase="stepStates.filled"
                id="icon"
                inline
                fontSet="fa"
                class="fa-solid fa-check"
                fontIcon="fa-check"
            >
            </mat-icon>
        </ng-container>

        <span class="body2">
            {{ stepLabel }}
        </span>
    </div>
</div>
