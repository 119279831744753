import { deploymentStates } from '../models/service';

// action status
export enum status {
    UNKNOW = -1,
    SLEEPING = 0,
    EXECUTING_MAIN = 1,
    IN_PROGRESS = 2,
    PAUSED = 3,
    SUCCESS = 4,
    ERROR = 5,
    CLOSED = 6,
    REVERTING = 7,
    REVERTED = 8,
}

export const affichageStatus = {
    [status.UNKNOW]: {
        message: 'unkown',
        class: 'status-badge-active',
        tagClass: 'wbce-tags-accent',
        active: false,
    },
    [status.SLEEPING]: {
        message: 'waiting',
        class: 'status-badge-waiting',
        active: true,
        tagClass: 'wbce-tags-waiting',
    },
    [status.EXECUTING_MAIN]: {
        message: 'execution',
        class: 'status-badge-active',
        active: true,
        tagClass: 'wbce-tags-accent',
    },
    [status.IN_PROGRESS]: {
        message: 'in progress',
        class: 'status-badge-active',
        active: true,
        tagClass: 'wbce-tags-accent',
    },
    [status.PAUSED]: {
        message: 'pause',
        class: 'status-badge-waiting',
        tagClass: 'wbce-tags-waiting',
        active: false,
    },
    [status.SUCCESS]: {
        message: 'success',
        class: 'status-badge-ok',
        tagClass: 'wbce-tags-primary',
        active: false,
    },
    [status.ERROR]: {
        message: 'in error',
        class: 'status-badge-error',
        tagClass: 'wbce-tags-error',
        active: false,
    },
    [status.CLOSED]: {
        message: 'closed',
        class: 'status-badge-inactive',
        tagClass: 'wbce-tags-inactive',
        active: false,
    },
    [status.REVERTING]: {
        message: 'reverting',
        class: 'status-badge-warning',
        active: true,
        tagClass: 'wbce-tags-warning',
    },
    [status.REVERTED]: {
        message: 'reverted',
        class: 'status-badge-ok',
        active: false,
        tagClass: 'wbce-tags-primary',
    },
};

export enum statusSupervisor {
    UNKNOW = -1,
    SLEEPING = 0,
    IN_PROGRESS = 2,
    PAUSED = 3,
    SUCCESS = 4,
    ERROR = 5,
    WAITING_FIRST_DEPLOYMENT = 6,
}

export const affichageStatusSupervisor = {
    [statusSupervisor.UNKNOW]: {
        message: 'unkown',
        class: 'status-badge-active',
        tagClass: 'wbce-tags-accent',
        active: false,
    },
    [statusSupervisor.SLEEPING]: {
        message: 'waiting',
        class: 'status-badge-waiting',
        active: true,
        tagClass: 'wbce-tags-waiting',
    },
    [statusSupervisor.IN_PROGRESS]: {
        message: 'in progress',
        class: 'status-badge-active',
        active: true,
        tagClass: 'wbce-tags-accent',
    },
    [statusSupervisor.PAUSED]: {
        message: 'pause',
        class: 'status-badge-waiting',
        tagClass: 'wbce-tags-waiting',
        active: false,
    },
    [statusSupervisor.SUCCESS]: {
        message: 'success',
        class: 'status-badge-ok',
        tagClass: 'wbce-tags-primary',
        active: false,
    },
    [statusSupervisor.ERROR]: {
        message: 'in error',
        class: 'status-badge-error',
        tagClass: 'wbce-tags-error',
        active: false,
    },
    [statusSupervisor.WAITING_FIRST_DEPLOYMENT]: {
        message: 'no deployment',
        class: 'status-badge-warning',
        tagClass: 'wbce-tags-warning',
        active: false,
    },
};

// see if it's a status
export enum statusCommit {
    IN_PROGRESS = 2,
    WARNING = 3,
    SUCCESS = 4,
    ERROR = 5,
    PREVIOUS = 6,
}

export const affichageStatusCommit = {
    [statusCommit.IN_PROGRESS]: {
        message: 'deployment in progress',
        status: 'in progress',
        tagClass: 'wbce-tags-accent',
        class: 'status-badge-active',
    },
    [statusCommit.SUCCESS]: {
        message: 'current version',
        status: 'success',
        tagClass: 'wbce-tags-primary',
        class: 'status-badge-ok',
    },
    [statusCommit.WARNING]: {
        message: 'version warning',
        status: 'warning',
        tagClass: 'wbce-tags-warning',
        class: 'status-badge-warning',
    },
    [statusCommit.ERROR]: {
        message: 'deployment error',
        status: 'error',
        tagClass: 'wbce-tags-error',
        class: 'status-badge-error',
    },
    [statusCommit.PREVIOUS]: {
        message: 'previous version',
        status: 'previous',
        tagClass: 'wbce-tags-inactive',
        class: 'status-badge-inactive',
    },
};

// sync with statusDeployment from back, warning only on front
export const affichageStatusDeployment = {
    [deploymentStates.IN_PROGRESS]: {
        message: 'in progress',
        tagClass: 'wbce-tags-accent',
        class: 'status-badge-active',
    },
    [deploymentStates.SUCCESS]: {
        message: 'success',
        tagClass: 'wbce-tags-primary',
        class: 'status-badge-ok',
    },
    [deploymentStates.ERROR]: {
        message: 'error',
        tagClass: 'wbce-tags-error',
        class: 'status-badge-error',
    },
    [deploymentStates.WARNING]: {
        // only on front
        message: 'warning',
        tagClass: 'wbce-tags-warning',
        class: 'status-badge-warning',
    },
};
